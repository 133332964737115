<template>
  <div class="main">
    <div class="wrapper" v-if="error === ''">
      <h1 class="display-2 font-weight-normal text-center mt-5">Thank you!</h1>
      <h4 class="font-weight-normal text-center">Your account has been verified.</h4>
      <h4 class="font-weight-normal text-center">Click <router-link :to="{ name: 'login', params: {} }">here</router-link> to login.</h4>
    </div>
    <div class="wrapper" v-else-if="error === 'already verified'">
      <h1 class="display-2 font-weight-normal text-center mt-5">Warning!</h1>
      <h4 class="font-weight-normal text-center">Your account has already been verified.</h4>
      <h4 class="font-weight-normal text-center">Click <router-link :to="{ name: 'login', params: {} }">here</router-link> to login.</h4>
    </div>
    <div class="wrapper" v-else>
      <h1 class="display-2 font-weight-normal text-center mt-5">Error!</h1>
      <h4 class="font-weight-normal text-center">{{ error }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'verified',
  props: [],
  components: {},
  data () {
    return {
      id: undefined,
      expires: undefined,
      hash: undefined,
      signature: undefined,
      error: ''
    }
  },
  methods: {
    silentVerify () {
      var url = '/verify/' + this.id + '?expires=' + this.expires + '&hash=' + this.hash + '&signature=' + this.signature
      this.axios
        .get(process.env.VUE_APP_API + url)
        .then((response) => {})
        .catch((error) => {
          const { data, status } = error?.response
          if (status === 401 && data?.message.includes('Email already verified')) {
            this.error = 'already verified'
          } else {
            this.error = data?.msg || 'Invalid provided url.'
          }
        })
    }
  },
  created () {
    const { params } = this.$route
    this.id = params?.id
    this.hash = this.$route.query.hash
    this.expires = this.$route.query.expires
    this.signature = this.$route.query.signature
  },
  mounted () {
    this.silentVerify()
  }
}
</script>
